<template>
  <v-card :loading="loading" height="100%">
    <div class="d-flex flex-row align-center justify-space-between">
      <v-card-title class="font-weight-regular text-subtitle-1 pa-4">
        Informações pessoais
      </v-card-title>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="openModal('Editando Informações Pessoais')"
            class="mr-2"
            fab
            x-small
            color="accent"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar informações</span>
      </v-tooltip>
    </div>

    <v-card-text class="pb-0">
      <p class="mb-2" v-if="candidato && candidato.nome">
        <strong>Nome completo:</strong> {{ candidato.nome }}
      </p>
      <p class="mb-2" v-if="candidato && candidato.habilitacao">
        <strong>Habilitação:</strong>
        <v-chip
          small
          class="mx-1"
          color="info"
          v-for="habilitacao in candidato.habilitacao"
          :key="habilitacao"
        >
          {{ habilitacao }}
        </v-chip>
      </p>
      <p class="mb-2" v-if="candidato && candidato.contato">
        <strong>Contato:</strong> {{ candidato.contato | maskForCel() }}
      </p>
      <p class="mb-2" v-if="candidato && candidato.email">
        <strong>Email:</strong> {{ candidato.email }}
      </p>
    </v-card-text>
    <v-card-actions class="pb-2">
      <v-btn
        text
        plain
        color="accent"
        @click="openModal('Visualizando Informações Pessoais', false)"
      >
        Ver mais...
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import InformacoesGeral from "@/modules/res/painel/components/components/components/InformacoesGeral.vue";

export default {
  name: "CardInformacoesPessoais",
  inject: ["$validator"],
  data() {
    return {
      status: {
        A: {
          text: "Ativo",
          color: "success",
        },
        I: {
          text: "Inativo",
          color: "secondary",
        },
      },

      loading: false,
    };
  },
  computed: {
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
    },
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        return this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
  },
  methods: {
    /**
     * @description Abre o modal e inseri todas as informações necessárias para a edição.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async openModal(title = String, isEdit = true) {
      this.loading = true;

      await this.$store.dispatch("resCandidatos/getAllDatasCandidato");

      const nameComponent = isEdit ? "componentEdit" : "componentView";

      const config = {
        title: title,
        dialog: true,
        hideButtonSave: true,
        nameComponent: nameComponent,
        component: InformacoesGeral,
      };

      this.configModal(config);

      this.loading = false;
    },

    informacoesPessoaisSave() {
      this.$validator.validate("res_candidatos.*").then((result) => {
        if (result) {
          // Lógica aplicada

          const config = {
            title: "",
            dialog: false,
            hideButtonSave: false,
            component: null,
            nameComponent: null,
            saveButtonClicked: false,
          };

          this.configModal(config);
        }
      });
    },

    /**
     * @description Configura o modal generico;
     *
     * @param options Object
     * @example `{ title: String, dialog: Boolean, saveButtonClicked: Boolean, hideButtonSave: Boolean, component: component }`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    configModal(options = Object) {
      Object.assign(this.genericModal, options);

      return this;
    },
  },
  watch: {
    /**
     * @description Verifica se a propriedade do modal saveButtonClicked ficou True, caso fique será executado a função de salvar e depois a de setar False para a propriedade novamente.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    "genericModal.saveButtonClicked"(now, old) {
      if (now) {
        if (this.genericModal.nameComponent == "informacoesPessoais") {
          const nameSaveFunction = `${this.genericModal.nameComponent}Save`;

          this[nameSaveFunction]();
          this.genericModal.saveButtonClicked = old;
        }
      }
    },
  },
};
</script>
