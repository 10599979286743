<template>
  <v-list v-if="orderCandidatosFormacoes">
    <v-list-item-group>
      <v-list-item
        v-for="item in orderCandidatosFormacoes"
        :key="item.id"
        two-line
        link
      >
        <v-list-item-content>
          <div class="d-flex flex-column flex-sm-row align-center">
            <div>
              <v-list-item-title class="font-weight-medium">
                {{ item.instituicao }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.curso }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.inicio | dateFormatBr() }} -
                <span v-if="!item.fim"> Até o momento </span>
                <span v-else>
                  {{ item.fim | dateFormatBr() }}
                </span>
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <v-btn
              :loading="
                loadings.loadingEdit == `loading-${item.id}` ? true : false
              "
              @click="edit(item)"
              class="mx-1"
              icon
            >
              <v-icon size="20" color="accent">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              :loading="
                loadings.loadingDelete == `loading-${item.id}` ? true : false
              "
              @click="remove(item)"
              class="mx-1"
              icon
            >
              <v-icon size="20" color="error">mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import CandidatosFormacoesForm from "@/modules/res/painel/components/components/forms/CandidatosFormacoesForm.vue";

export default {
  name: "ListaCandidatosFormacoes",
  data() {
    return {
      loadings: {
        loadingDelete: "",
        loadingEdit: "",
      },
    };
  },
  methods: {
    create() {
      const config = {
        title: "Adicionar nova formação",
        nameComponent: "candidatoFormacao",
        hideButtonSave: false,
        hideButtonReturn: false,
        hidePlusButton: true,
        component: CandidatosFormacoesForm,
      };

      this.configModal(config);
    },
    edit(item) {
      this.loadings.loadingEdit = `loading-${item.id}`;

      this.$store
        .dispatch("resCandidatosFormacoes/findByUuid", item.uuid)
        .then((response) => {
          if (response) {
            const config = {
              title: "Editar formação",
              nameComponent: "candidatoFormacao",
              hideButtonSave: false,
              hideButtonReturn: false,
              component: CandidatosFormacoesForm,
            };

            this.configModal(config);
          }
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem:
              "Houve um erro ao buscar pela formação. Por favor, tente novamente mais tarde.",
            duracao: 3000,
          });
        })
        .finally(() => {
          this.loadings.loadingEdit = "";
        });
    },
    remove(item) {
      this.loadings.loadingDelete = `loading-${item.id}`;

      this.$store
        .dispatch("resCandidatosFormacoes/remove", item.uuid)
        .then((response) => {
          const index = this.candidatosFormacoes.findIndex(
            (el) => el.id == item.id
          );

          this.candidatosFormacoes.splice(index, 1);

          // Remove 1 da contagem de experiencias profissionais
          this.quantidadeFormacao = parseInt(this.quantidadeFormacao) - 1;

          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "green",
            mensagem: "Formação deletada com sucesso",
            duracao: 3000,
          });
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem: "Houve um erro ao deletar a formação",
            duracao: 3000,
          });
        })
        .finally(() => {
          this.loadings.loadingDelete = "";
        });
    },

    /**
     * @description Configura o modal generico;
     *
     * @param options Object
     * @example `{ title: String, dialog: Boolean, saveButtonClicked: Boolean, hideButtonSave: Boolean, component: component }`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    configModal(options = Object) {
      Object.assign(this.genericModal, options);

      return this;
    },
  },
  computed: {
    candidatosFormacoes: {
      get() {
        return this.$store.getters[
          "resCandidatosFormacoes/getCandidatosFormacoes"
        ];
      },
    },
    /**
     * @description Ordena o array de experiencias desde o emprego atual até o mais antigo.
     */
    orderCandidatosFormacoes() {
      if (!this.candidatosFormacoes || this.candidatosFormacoes.length == 0)
        return [];

      const candidatosFormacoesOrdenados = this.candidatosFormacoes.sort(
        function (el1, el2) {
          if (el1.inicio < el2.inicio) {
            return 1;
          }
          if (el1.inicio > el2.inicio) {
            return -1;
          }

          return 0;
        }
      );

      return candidatosFormacoesOrdenados;
    },
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        return this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
    quantidadeFormacao: {
      get() {
        return this.$store.getters[
          "resCandidatosFormacoes/getQuantidadeFormacao"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "resCandidatosFormacoes/setQuantidadeFormacao",
          value
        );
      },
    },
  },
  watch: {
    "genericModal.plusButtonClicked"(now, old) {
      if (now) {
        this.create();
        this.genericModal.plusButtonClicked = false;
      }
    },
  },
};
</script>
