import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"curso",attrs:{"label":"Curso","placeholder":"Ex.: Engenharia civil.","outlined":"","dense":"","data-vv-as":"curso","persistent-hint":"","hint":_vm.errors.first('res-candidato-formacao.curso'),"error":_vm.errors.collect('res-candidato-formacao.curso').length ? true : false,"hide-details":_vm.errors.collect('res-candidato-formacao.curso').length ? false : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-formacao","id":"curso","name":"curso"},model:{value:(_vm.resFormacao.curso),callback:function ($$v) {_vm.$set(_vm.resFormacao, "curso", $$v)},expression:"resFormacao.curso"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"instituicao",attrs:{"label":"Instituição","placeholder":"Ex.: Faculdade de Engenharia.","outlined":"","dense":"","data-vv-as":"instituição","persistent-hint":"","hint":_vm.errors.first('res-candidato-formacao.instituicao'),"error":_vm.errors.collect('res-candidato-formacao.instituicao').length
          ? true
          : false,"hide-details":_vm.errors.collect('res-candidato-formacao.instituicao').length
          ? false
          : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-formacao","id":"instituicao","name":"instituicao"},model:{value:(_vm.resFormacao.instituicao),callback:function ($$v) {_vm.$set(_vm.resFormacao, "instituicao", $$v)},expression:"resFormacao.instituicao"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VMenu,{ref:"menuInicio",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Data de início","persistent-hint":"","prepend-icon":"mdi-calendar","outlined":"","dense":"","data-vv-as":"data de início","hint":_vm.errors.first('res-candidato-formacao.inicio'),"error":_vm.errors.collect('res-candidato-formacao.inicio').length
              ? true
              : false,"hide-details":_vm.errors.collect('res-candidato-formacao.inicio').length
              ? false
              : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-formacao","id":"inicio","name":"inicio"},on:{"blur":function($event){_vm.resFormacao.inicio = _vm.parseDate(_vm.dateFormattedInicio)}},model:{value:(_vm.dateFormattedInicio),callback:function ($$v) {_vm.dateFormattedInicio=$$v},expression:"dateFormattedInicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuInicio),callback:function ($$v) {_vm.menuInicio=$$v},expression:"menuInicio"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.menuInicio = false}},model:{value:(_vm.resFormacao.inicio),callback:function ($$v) {_vm.$set(_vm.resFormacao, "inicio", $$v)},expression:"resFormacao.inicio"}})],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VMenu,{ref:"menuFim",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Data de Fim","persistent-hint":"","prepend-icon":"mdi-calendar","outlined":"","dense":"","data-vv-as":"data de fim","hint":_vm.errors.first('res-candidato-formacao.fim'),"error":_vm.errors.collect('res-candidato-formacao.fim').length ? true : false,"hide-details":_vm.errors.collect('res-candidato-formacao.fim').length ? false : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-formacao","id":"fim","name":"fim"},on:{"blur":function($event){_vm.resFormacao.fim = _vm.parseDate(_vm.dateFormattedFim)}},model:{value:(_vm.dateFormattedFim),callback:function ($$v) {_vm.dateFormattedFim=$$v},expression:"dateFormattedFim"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFim),callback:function ($$v) {_vm.menuFim=$$v},expression:"menuFim"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.menuFim = false}},model:{value:(_vm.resFormacao.fim),callback:function ($$v) {_vm.$set(_vm.resFormacao, "fim", $$v)},expression:"resFormacao.fim"}})],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"loading":_vm.loadings.loadingFormacao,"items":_vm.resFormacoes,"item-text":"descricao","item-value":"id","label":"Formação","placeholder":"Selecione uma formação","outlined":"","dense":"","data-vv-as":"formação","persistent-hint":"","hint":_vm.errors.first('res-candidato-formacao.resFormacao'),"error":_vm.errors.collect('res-candidato-formacao.resFormacao').length
          ? true
          : false,"hide-details":_vm.errors.collect('res-candidato-formacao.resFormacao').length
          ? false
          : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-formacao","id":"resFormacao","name":"resFormacao"},model:{value:(_vm.resFormacao.id_formacao),callback:function ($$v) {_vm.$set(_vm.resFormacao, "id_formacao", $$v)},expression:"resFormacao.id_formacao"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"loading":_vm.loadings.loadingStatus,"items":_vm.resStatusCurso,"item-text":"descricao","item-value":"id","label":"Status","placeholder":"Selecione um status","outlined":"","dense":"","data-vv-as":"status","persistent-hint":"","hint":_vm.errors.first('res-candidato-formacao.status'),"error":_vm.errors.collect('res-candidato-formacao.status').length ? true : false,"hide-details":_vm.errors.collect('res-candidato-formacao.status').length ? false : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-formacao","id":"status","name":"status"},model:{value:(_vm.resFormacao.status),callback:function ($$v) {_vm.$set(_vm.resFormacao, "status", $$v)},expression:"resFormacao.status"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }