<template>
  <v-row>
    <!-- curso -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="Curso"
        ref="curso"
        placeholder="Ex.: Engenharia civil."
        outlined
        dense
        v-model="resFormacao.curso"
        data-vv-as="curso"
        persistent-hint
        :hint="errors.first('res-candidato-formacao.curso')"
        :error="
          errors.collect('res-candidato-formacao.curso').length ? true : false
        "
        :hide-details="
          errors.collect('res-candidato-formacao.curso').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-formacao"
        id="curso"
        name="curso"
      ></v-text-field>
    </v-col>
    <!-- instituicao -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="Instituição"
        ref="instituicao"
        placeholder="Ex.: Faculdade de Engenharia."
        outlined
        dense
        v-model="resFormacao.instituicao"
        data-vv-as="instituição"
        persistent-hint
        :hint="errors.first('res-candidato-formacao.instituicao')"
        :error="
          errors.collect('res-candidato-formacao.instituicao').length
            ? true
            : false
        "
        :hide-details="
          errors.collect('res-candidato-formacao.instituicao').length
            ? false
            : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-formacao"
        id="instituicao"
        name="instituicao"
      ></v-text-field>
    </v-col>
    <!-- Data inicio -->
    <v-col cols="12" sm="6">
      <v-menu
        ref="menuInicio"
        v-model="menuInicio"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormattedInicio"
            label="Data de início"
            persistent-hint
            prepend-icon="mdi-calendar"
            outlined
            dense
            v-bind="attrs"
            @blur="resFormacao.inicio = parseDate(dateFormattedInicio)"
            v-on="on"
            data-vv-as="data de início"
            :hint="errors.first('res-candidato-formacao.inicio')"
            :error="
              errors.collect('res-candidato-formacao.inicio').length
                ? true
                : false
            "
            :hide-details="
              errors.collect('res-candidato-formacao.inicio').length
                ? false
                : true
            "
            v-validate="'required'"
            data-vv-validate-on="change|blur|input"
            data-vv-scope="res-candidato-formacao"
            id="inicio"
            name="inicio"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="resFormacao.inicio"
          no-title
          @input="menuInicio = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <!-- Data fim -->
    <v-col cols="12" sm="6">
      <v-menu
        ref="menuFim"
        v-model="menuFim"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormattedFim"
            label="Data de Fim"
            persistent-hint
            prepend-icon="mdi-calendar"
            outlined
            dense
            v-bind="attrs"
            @blur="resFormacao.fim = parseDate(dateFormattedFim)"
            v-on="on"
            data-vv-as="data de fim"
            :hint="errors.first('res-candidato-formacao.fim')"
            :error="
              errors.collect('res-candidato-formacao.fim').length ? true : false
            "
            :hide-details="
              errors.collect('res-candidato-formacao.fim').length ? false : true
            "
            v-validate="'required'"
            data-vv-validate-on="change|blur|input"
            data-vv-scope="res-candidato-formacao"
            id="fim"
            name="fim"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="resFormacao.fim"
          no-title
          @input="menuFim = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <!-- Formacao -->
    <v-col cols="12" sm="6">
      <v-select
        :loading="loadings.loadingFormacao"
        :items="resFormacoes"
        v-model="resFormacao.id_formacao"
        item-text="descricao"
        item-value="id"
        label="Formação"
        placeholder="Selecione uma formação"
        outlined
        dense
        data-vv-as="formação"
        persistent-hint
        :hint="errors.first('res-candidato-formacao.resFormacao')"
        :error="
          errors.collect('res-candidato-formacao.resFormacao').length
            ? true
            : false
        "
        :hide-details="
          errors.collect('res-candidato-formacao.resFormacao').length
            ? false
            : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-formacao"
        id="resFormacao"
        name="resFormacao"
      ></v-select>
    </v-col>
    <!-- Status -->
    <v-col cols="12" sm="6">
      <v-select
        :loading="loadings.loadingStatus"
        :items="resStatusCurso"
        v-model="resFormacao.status"
        item-text="descricao"
        item-value="id"
        label="Status"
        placeholder="Selecione um status"
        outlined
        dense
        data-vv-as="status"
        persistent-hint
        :hint="errors.first('res-candidato-formacao.status')"
        :error="
          errors.collect('res-candidato-formacao.status').length ? true : false
        "
        :hide-details="
          errors.collect('res-candidato-formacao.status').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-formacao"
        id="status"
        name="status"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CandidatosFormacoesForm",
  inject: ["$validator"],
  data: () => ({
    dateFormattedInicio: null,
    dateFormattedFim: null,
    menuInicio: false,
    menuFim: false,

    loadings: {
      loadingFormacao: true,
      loadingStatus: true,
    },
  }),
  async created() {
    const dataFormacao = {
      columns: ["id", "descricao"],
    };
    const dataStatus = {
      columns: ["id", "descricao"],
      validate: [["status", "A"]],
    };

    await this.$store.dispatch("resFormacoes/findByColumns", dataFormacao);

    this.loadings.loadingFormacao = false;

    await this.$store.dispatch("resStatusCursos/findByColumns", dataStatus);

    this.loadings.loadingStatus = false;
  },
  computed: {
    resFormacao: {
      get() {
        const resFormacao =
          this.$store.getters["resCandidatosFormacoes/getCandidatoFormacao"];

        this.dateFormattedInicio = this.formatDate(resFormacao.inicio);
        this.dateFormattedFim = this.formatDate(resFormacao.fim);

        return resFormacao;
      },
      set(value) {
        this.$store.dispatch(
          "resCandidatosFormacoes/setCandidatoFormacao",
          value
        );
      },
    },
    resFormacoes: {
      get() {
        return this.$store.getters["resFormacoes/getFormacoes"];
      },
    },
    resStatusCurso: {
      get() {
        return this.$store.getters["resStatusCursos/getStatusCursos"];
      },
    },
  },
  watch: {
    "resFormacao.inicio"(val) {
      this.dateFormattedInicio = this.formatDate(this.resFormacao.inicio);
    },
    "resFormacao.fim"(val) {
      this.dateFormattedFim = this.formatDate(this.resFormacao.fim);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style></style>
