import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loading,"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row align-center justify-space-between"},[_c(VCardTitle,[_vm._v("Formação Acadêmica ("+_vm._s(_vm.quantidadeFormacao)+")")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","x-small":"","color":"accent"},on:{"click":function($event){return _vm.openForm()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nova formação")])])],1),_c(VCardText,[(_vm.candidatosFormacoes[0])?_c(VList,[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.candidatosFormacoes[0].instituicao)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.candidatosFormacoes[0].curso)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.candidatosFormacoes[0].inicio)+" - "+_vm._s(_vm.candidatosFormacoes[0].fim)+" ")])],1)],1)],1):_c('span',[_vm._v("Nenhuma formação registrada.")])],1),_c(VCardActions,{staticClass:"pb-2"},[_c(VBtn,{attrs:{"text":"","plain":"","color":"accent"},on:{"click":function($event){return _vm.viewMore()}}},[_vm._v("Ver mais...")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }