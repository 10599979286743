<template>
  <v-card :loading="loading" height="100%">
    <div
      class="d-flex flex-column flex-sm-row align-center justify-space-between"
    >
      <v-card-title>Formação Acadêmica ({{ quantidadeFormacao }})</v-card-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="accent"
            v-bind="attrs"
            v-on="on"
            @click="openForm()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Nova formação</span>
      </v-tooltip>
    </div>

    <v-card-text>
      <v-list v-if="candidatosFormacoes[0]">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              {{ candidatosFormacoes[0].instituicao }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ candidatosFormacoes[0].curso }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ candidatosFormacoes[0].inicio }} -
              {{ candidatosFormacoes[0].fim }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <span v-else>Nenhuma formação registrada.</span>
    </v-card-text>
    <v-card-actions class="pb-2">
      <v-btn text plain color="accent" @click="viewMore()">Ver mais...</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ListaCandidatosFormacoes from "@/modules/res/painel/components/components/components/ListaCandidatosFormacoes.vue";
import CandidatosFormacoesForm from "@/modules/res/painel/components/components/forms/CandidatosFormacoesForm.vue";

export default {
  name: "CardCandidatoFormacao",
  inject: ["$validator"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    candidatosFormacoes: {
      get() {
        return this.$store.getters[
          "resCandidatosFormacoes/getCandidatosFormacoes"
        ];
      },
    },
    candidatoFormacao: {
      get() {
        return this.$store.getters[
          "resCandidatosFormacoes/getCandidatoFormacao"
        ];
      },
    },
    quantidadeFormacao: {
      get() {
        return this.$store.getters[
          "resCandidatosFormacoes/getQuantidadeFormacao"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "resCandidatosFormacoes/setQuantidadeFormacao",
          value
        );
      },
    },
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        return this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
    },
  },
  methods: {
    openForm() {
      // Reseta os dados
      this.$store.dispatch("resCandidatosFormacoes/resetCandidatoFormacao");

      const config = {
        title: "Adicionar nova formação",
        nameComponent: "candidatoFormacao", // NOTE O método candidatoFormacao se encontra no componente CardCandidatoFormacao
        hideButtonSave: false,
        hideButtonReturn: true,
        dialog: true,
        component: CandidatosFormacoesForm,
      };

      this.configModal(config);
    },

    async viewMore() {
      this.loading = true;

      await this.getDatas();

      const config = {
        title: "Visualizando Formações Acadêmicas",
        dialog: true,
        hideButtonSave: true,
        hidePlusButton: false,
        component: ListaCandidatosFormacoes,
      };

      this.configModal(config);

      this.loading = false;
    },

    /**
     * @description Busca os dados da api.
     *
     * @param validate
     * @param columns
     */
    async getDatas(
      validate = [["id_usuario", this.candidato.id]],
      columns = [
        "id",
        "uuid",
        "curso",
        "instituicao",
        "inicio",
        "fim",
        "status",
      ]
    ) {
      await this.$store.dispatch("resCandidatosFormacoes/findByColumns", {
        validate,
        columns,
      });

      return this;
    },

    /**
     * @description Configura o modal generico;
     *
     * @param options Object
     * @example `{ title: String, dialog: Boolean, saveButtonClicked: Boolean, hideButtonSave: Boolean, component: component }`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    configModal(options = Object) {
      Object.assign(this.genericModal, options);

      return this;
    },

    candidatoFormacaoSave() {
      this.$validator.validate("res-candidato-formacao.*").then((result) => {
        if (result) {
          this.configModal({ loadingButtonSave: true });

          const isEdit =
            this.candidatoFormacao.id || this.candidatoFormacao.uuid
              ? true
              : false;

          const url =
            isEdit == false
              ? "/res-candidato-formacao"
              : `/res-candidato-formacao/${this.candidatoFormacao.uuid}`;
          const method = isEdit == false ? "POST" : "PUT";
          const errorMessage =
            isEdit == false
              ? "Houve um erro ao salvar a formação"
              : "Houve um erro ao atualizar a formação";
          const successMessage =
            isEdit == false
              ? "Formação salva com sucesso"
              : "Formação atualizada com sucesso";

          const data = {
            url: url,
            data: {
              ...this.candidatoFormacao,
              _method: method,
            },
          };

          this.$store
            .dispatch("resCandidatosFormacoes/save", data)
            .then(async (response) => {
              if (response) {
                // Assim que o objeto é salvo será feita uma busca na api trazendo os dados atualizados.
                await this.getDatas();

                if (isEdit == false) {
                  // Adiciona mais 1 na contagem de experiencias profissionais
                  this.quantidadeFormacao =
                    parseInt(this.quantidadeFormacao) + 1;
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "green",
                  mensagem: successMessage,
                  duracao: 3000,
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "red",
                mensagem: errorMessage,
                duracao: 3000,
              });
            })
            .finally(() => {
              const config = {
                title: "Visualizando Formações Acadêmicas",
                hideButtonSave: true,
                component: ListaCandidatosFormacoes,
                loadingButtonSave: false,
                hidePlusButton: false,
              };

              this.configModal(config);

              // Reseta os dados da candidatoFormacao
              this.$store.dispatch(
                "resCandidatosFormacoes/resetCandidatoFormacao"
              );
            });
        }
      });
    },
  },
  watch: {
    /**
     * @description Verifica se a propriedade do modal saveButtonClicked ficou True, caso fique será executado a função de salvar e depois a de setar False para a propriedade novamente.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    "genericModal.saveButtonClicked"(now, old) {
      if (now) {
        if (this.genericModal.nameComponent == "candidatoFormacao") {
          const nameSaveFunction = `${this.genericModal.nameComponent}Save`;

          this[nameSaveFunction]();
          this.genericModal.saveButtonClicked = old;
          this.genericModal.hideButtonReturn = true;
        }
      }
    },
    "genericModal.returnButtonClicked"(now, old) {
      if (now && this.genericModal.nameComponent == "candidatoFormacao") {
        this.genericModal.component = ListaCandidatosFormacoes;
        this.genericModal.hideButtonReturn = true;
        this.genericModal.hideButtonSave = true;
        this.genericModal.returnButtonClicked = old;
        this.genericModal.hidePlusButton = false;

        // Reseta os dados
        this.$store.dispatch("resCandidatosFormacoes/resetCandidatoFormacao");
      }
    },
  },
};
</script>

<style></style>
