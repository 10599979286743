<template>
  <v-card :loading="loading" height="100%">
    <div class="d-flex flex-row align-center justify-space-between">
      <v-card-title>Objetivo profissional</v-card-title>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="edit()"
            class="mr-2"
            fab
            x-small
            color="accent"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar objetivo</span>
      </v-tooltip>
    </div>

    <v-card-text>
      <p v-if="objetivoProfissional.objetivo_profissional" class="pa-0 ma-0">
        {{ objetivoProfissional.objetivo_profissional }}
      </p>
      <h3 v-else class="text-subtitle-1 font-weight-medium">
        Não há nenhum objetivo profissional.
      </h3>
    </v-card-text>
  </v-card>
</template>

<script>
import ObjetivosProfissionaisForm from "@/modules/res/painel/components/components/forms/ObjetivosProfissionaisForm.vue";

export default {
  name: "CardObjetivosProfissionais",
  inject: ["$validator"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    objetivoProfissional: {
      get() {
        return this.$store.getters[
          "resObjetivosProfissionais/getObjetivoProfissional"
        ];
      },
    },
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
    },
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        return this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
  },
  methods: {
    /**
     * @description Abre o modal e inseri todas as informações necessárias para a edição.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async edit() {
      this.loading = true;

      // await this.$store.dispatch("resCandidatos/getAllDataCandidato");

      const config = {
        title: "Editando objetivo profissional",
        dialog: true,
        nameComponent: "objetivoProfissional",
        component: ObjetivosProfissionaisForm,
      };

      this.configModal(config);

      this.loading = false;
    },

    objetivoProfissionalSave() {
      this.$validator.validate("res_objetivo_profissional.*").then((result) => {
        if (result) {
          const data = {
            url: `/res-candidato-perfil/${this.objetivoProfissional.uuid}`,
            data: {
              objetivo_profissional:
                this.objetivoProfissional.objetivo_profissional,
              _method: "PUT",
            },
          };

          this.loading = true;

          this.$store
            .dispatch("resObjetivosProfissionais/save", data)
            .then((response) => {
              if (response) {
                this.$store.dispatch("snackBarModule/openSnackBar", {
                  color: "success",
                  timeout: 3000,
                  text: "Objetivo profissional atualizado com sucesso.",
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Houve um erro ao atualizar o objetivo profissional. Por favor, tente novamente mais tarde!",
              });
            })
            .finally(() => {
              this.loading = false;
            });

          // Limpa o modal e fecha o mesmo
          const config = {
            title: "",
            dialog: false,
            hideButtonSave: false,
            component: null,
            nameComponent: null,
            saveButtonClicked: false,
          };

          this.configModal(config);
        }
      });
    },

    /**
     * @description Configura o modal generico;
     *
     * @param options Object
     * @example `{ title: String, dialog: Boolean, saveButtonClicked: Boolean, hideButtonSave: Boolean, component: component }`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    configModal(options = Object) {
      Object.assign(this.genericModal, options);

      return this;
    },
  },
  watch: {
    /**
     * @description Verifica se a propriedade do modal saveButtonClicked ficou True, caso fique será executado a função de salvar e depois a de setar False para a propriedade novamente.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    "genericModal.saveButtonClicked"(now, old) {
      if (now) {
        if (this.genericModal.nameComponent == "objetivoProfissional") {
          const nameSaveFunction = `${this.genericModal.nameComponent}Save`;

          this[nameSaveFunction]();
          this.genericModal.saveButtonClicked = old;
        }
      }
    },
  },
};
</script>

<style></style>
