<template>
  <v-list v-if="orderExperienciasProfissionais">
    <v-list-item-group>
      <v-list-item
        v-for="item in orderExperienciasProfissionais"
        :key="item.id"
        two-line
        link
      >
        <v-list-item-content>
          <div class="d-flex flex-column flex-sm-row align-center">
            <div>
              <v-list-item-title class="font-weight-medium">
                {{ item.funcao }}
                <span class="font-weight-regular secondary--text lighten-3">
                  - {{ item.empresa }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.inicio | dateFormatBr() }} -
                <span v-if="!item.fim && item.trabalho_atual">
                  Até o momento
                </span>
                <span v-else>
                  {{ item.fim | dateFormatBr() }}
                </span>
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.salario">
                R$ {{ item.salario | numberFormatBrWithoutCurrency() }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Salário não informado
              </v-list-item-subtitle>

              <v-list-item-action-text>
                {{ item.descricao }}
              </v-list-item-action-text>
            </div>

            <v-spacer></v-spacer>

            <v-btn
              :loading="
                loadings.loadingEdit == `loading-${item.id}` ? true : false
              "
              @click="edit(item)"
              class="mx-1"
              icon
            >
              <v-icon size="20" color="accent">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              :loading="
                loadings.loadingDelete == `loading-${item.id}` ? true : false
              "
              @click="remove(item)"
              class="mx-1"
              icon
            >
              <v-icon size="20" color="error">mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import ExperienciasProfissionaisForm from "@/modules/res/painel/components/components/forms/ExperienciasProfissionaisForm.vue";

export default {
  name: "ListaExperienciasProfissionais",
  data() {
    return {
      loadings: {
        loadingDelete: "",
        loadingEdit: "",
      },
    };
  },
  methods: {
    create() {
      const config = {
        title: "Adicionar nova experiência",
        nameComponent: "experienciaProfissional",
        hideButtonSave: false,
        hideButtonReturn: false,
        hidePlusButton: true,
        component: ExperienciasProfissionaisForm,
      };

      this.configModal(config);
    },
    edit(item) {
      this.loadings.loadingEdit = `loading-${item.id}`;

      this.$store
        .dispatch("resExperienciasProfissionais/findByUuid", item.uuid)
        .then((response) => {
          if (response) {
            const config = {
              title: "Editar experiência",
              nameComponent: "experienciaProfissional",
              hideButtonSave: false,
              hideButtonReturn: false,
              component: ExperienciasProfissionaisForm,
            };

            this.configModal(config);
          }
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem:
              "Houve um erro ao buscar pela experiência. Por favor, tente novamente mais tarde.",
            duracao: 3000,
          });
        })
        .finally(() => {
          this.loadings.loadingEdit = "";
        });
    },
    remove(item) {
      this.loadings.loadingDelete = `loading-${item.id}`;

      this.$store
        .dispatch("resExperienciasProfissionais/remove", item.uuid)
        .then((response) => {
          const index = this.experienciasProfissionais.findIndex(
            (el) => el.id == item.id
          );

          this.experienciasProfissionais.splice(index, 1);

          // Remove 1 da contagem de experiencias profissionais
          this.quantidadeExperiencia = parseInt(this.quantidadeExperiencia) - 1;

          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "green",
            mensagem: "Experiência deletada com sucesso",
            duracao: 3000,
          });
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem: "Houve um erro ao deletar a experiência",
            duracao: 3000,
          });
        })
        .finally(() => {
          this.loadings.loadingDelete = "";
        });
    },

    /**
     * @description Configura o modal generico;
     *
     * @param options Object
     * @example `{ title: String, dialog: Boolean, saveButtonClicked: Boolean, hideButtonSave: Boolean, component: component }`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    configModal(options = Object) {
      Object.assign(this.genericModal, options);

      return this;
    },
  },
  computed: {
    experienciasProfissionais: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getExperienciasProfissionais"
        ];
      },
    },
    /**
     * @description Ordena o array de experiencias desde o emprego atual até o mais antigo.
     */
    orderExperienciasProfissionais() {
      if (
        !this.experienciasProfissionais ||
        this.experienciasProfissionais.length == 0
      )
        return [];

      const experienciasProfissionaisOrdenadas =
        this.experienciasProfissionais.sort(function (el1, el2) {
          if (el1.inicio < el2.inicio) {
            return 1;
          }
          if (el1.inicio > el2.inicio) {
            return -1;
          }

          return 0;
        });

      return experienciasProfissionaisOrdenadas;
    },
    experienciaProfissional: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getExperienciaProfissional"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "resExperienciasProfissionais/setExperienciaProfissional",
          value
        );
      },
    },
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        return this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
    quantidadeExperiencia: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getQuantidadeExperiencia"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "resExperienciasProfissionais/setQuantidadeExperiencia",
          value
        );
      },
    },
  },
  watch: {
    "genericModal.plusButtonClicked"(now, old) {
      if (now) {
        this.create();
        this.genericModal.plusButtonClicked = false;
      }
    },
  },
};
</script>

<style></style>
