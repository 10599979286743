<template>
  <v-card :loading="loading" height="100%">
    <div
      class="d-flex flex-column flex-sm-row align-center justify-space-between"
    >
      <v-card-title>
        Experiência profissional ({{ quantidadeExperiencia }})
      </v-card-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="accent"
            v-bind="attrs"
            v-on="on"
            @click="openForm()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Nova experiência</span>
      </v-tooltip>
    </div>

    <v-card-text>
      <v-list v-if="experienciasProfissionais[0]">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              {{ experienciasProfissionais[0].funcao }}
              <span class="font-weight-regular secondary--text lighten-3">
                - {{ experienciasProfissionais[0].empresa }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ experienciasProfissionais[0].inicio | dateFormatBr() }} -
              <span
                v-if="
                  !experienciasProfissionais[0].fim &&
                  experienciasProfissionais[0].trabalho_atual
                "
                >Até o momento
              </span>
              <span v-else>
                {{ experienciasProfissionais[0].fim | dateFormatBr() }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <span v-else>Nenhuma experiência registrada.</span>
    </v-card-text>
    <v-card-actions class="pb-2">
      <v-btn text plain color="accent" @click="viewMore()">Ver mais...</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ListaExperienciasProfissionais from "@/modules/res/painel/components/components/components/ListaExperienciasProfissionais.vue";
import { convertCurrencyToDouble } from "@/helpers/index";
import { getDate } from "@/helpers/index";

import ExperienciasProfissionaisForm from "@/modules/res/painel/components/components/forms/ExperienciasProfissionaisForm.vue";

export default {
  name: "CardExperienciaProfissional",
  inject: ["$validator"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    experienciasProfissionais: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getExperienciasProfissionais"
        ];
      },
    },
    quantidadeExperiencia: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getQuantidadeExperiencia"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "resExperienciasProfissionais/setQuantidadeExperiencia",
          value
        );
      },
    },
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
      set(value) {
        return this.$store.dispatch("resCandidatos/setModal", value);
      },
    },
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
    },
    experienciaProfissional: {
      get() {
        return this.$store.getters[
          "resExperienciasProfissionais/getExperienciaProfissional"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "resExperienciasProfissionais/setExperienciaProfissional",
          value
        );
      },
    },
  },
  methods: {
    openForm() {
      // Reseta os dados
      this.$store.dispatch(
        "resExperienciasProfissionais/resetExperienciaProfissional"
      );

      const config = {
        title: "Adicionar nova experiência",
        nameComponent: "experienciaProfissional", // NOTE O método experienciaProfissional se encontra no componente CardExperienciaProfissional
        hideButtonSave: false,
        hideButtonReturn: true,
        dialog: true,
        component: ExperienciasProfissionaisForm,
      };

      this.configModal(config);
    },

    async viewMore() {
      this.loading = true;

      await this.getDatas();

      const config = {
        title: "Visualizando Experiências Profissionais",
        dialog: true,
        hideButtonSave: true,
        hidePlusButton: false,
        component: ListaExperienciasProfissionais,
      };

      this.configModal(config);

      this.loading = false;
    },

    /**
     * @description Salva a experiencia profissional do usuário
     */
    experienciaProfissionalSave() {
      this.$validator.validate("res-experiencia.*").then((result) => {
        if (result) {
          this.configModal({ loadingButtonSave: true });

          const isEdit =
            this.experienciaProfissional.id || this.experienciaProfissional.uuid
              ? true
              : false;

          const url =
            isEdit == false
              ? "/res-candidato-experiencia"
              : `/res-candidato-experiencia/${this.experienciaProfissional.uuid}`;
          const method = isEdit == false ? "POST" : "PUT";
          const errorMessage =
            isEdit == false
              ? "Houve um erro ao salvar a experiência"
              : "Houve um erro ao atualizar a experiência";
          const successMessage =
            isEdit == false
              ? "Experiência salva com sucesso"
              : "Experiência atualizada com sucesso";

          this.experienciaProfissional.salario = convertCurrencyToDouble(
            this.experienciaProfissional.salario
          );

          if (this.experienciaProfissional.trabalho_atual == "1")
            this.experienciaProfissional.fim = null;

          const data = {
            url: url,
            data: {
              ...this.experienciaProfissional,
              _method: method,
            },
          };

          this.$store
            .dispatch("resExperienciasProfissionais/save", data)
            .then(async (response) => {
              if (response) {
                // Assim que o objeto é salvo será feita uma busca na api trazendo os dados atualizados.
                await this.getDatas();

                if (isEdit == false) {
                  // Adiciona mais 1 na contagem de experiencias profissionais
                  this.quantidadeExperiencia =
                    parseInt(this.quantidadeExperiencia) + 1;
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "green",
                  mensagem: successMessage,
                  duracao: 3000,
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "red",
                mensagem: errorMessage,
                duracao: 3000,
              });
            })
            .finally(() => {
              const config = {
                title: "Visualizando Experiências Profissionais",
                hideButtonSave: true,
                component: ListaExperienciasProfissionais,
                loadingButtonSave: false,
                hidePlusButton: false,
              };

              this.configModal(config);

              // Reseta os dados da experienciaProfissional
              this.$store.dispatch(
                "resExperienciasProfissionais/resetExperienciaProfissional"
              );
            });
        }
      });
    },

    /**
     * @description Busca os dados da api.
     *
     * @param validate
     * @param columns
     */
    async getDatas(
      validate = [["id_usuario", this.candidato.id]],
      columns = [
        "id",
        "uuid",
        "funcao",
        "empresa",
        "salario",
        "inicio",
        "fim",
        "trabalho_atual",
        "descricao",
      ]
    ) {
      await this.$store.dispatch("resExperienciasProfissionais/findByColumns", {
        validate,
        columns,
      });

      return this;
    },

    /**
     * @description Caso o item a ser criado tiver trabalho_atual igual a 1 então a função irá buscar por outro item que já tenha trabalho_atual igual a 1 e será retirado.
     */
    changeTrabalhoAtual(item) {
      if (item.trabalho_atual == "1") {
        const index = this.experienciasProfissionais.findIndex(
          (el) => el.trabalho_atual == "1"
        );

        if (index != -1) {
          const currentDate = getDate();

          const data = {
            trabalho_atual: "0",
            fim: currentDate,
          };

          Object.assign(this.experienciasProfissionais[index], data);
        }
      }

      return this;
    },

    /**
     * @description Configura o modal generico;
     *
     * @param options Object
     * @example `{ title: String, dialog: Boolean, saveButtonClicked: Boolean, hideButtonSave: Boolean, component: component }`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    configModal(options = Object) {
      Object.assign(this.genericModal, options);

      return this;
    },
  },
  watch: {
    /**
     * @description Verifica se a propriedade do modal saveButtonClicked ficou True, caso fique será executado a função de salvar e depois a de setar False para a propriedade novamente.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    "genericModal.saveButtonClicked"(now, old) {
      if (now) {
        if (this.genericModal.nameComponent == "experienciaProfissional") {
          const nameSaveFunction = `${this.genericModal.nameComponent}Save`;

          this[nameSaveFunction]();
          this.genericModal.saveButtonClicked = old;
          this.genericModal.hideButtonReturn = true;
        }
      }
    },
    "genericModal.returnButtonClicked"(now, old) {
      if (now && this.genericModal.nameComponent == "experienciaProfissional") {
        this.genericModal.component = ListaExperienciasProfissionais;
        this.genericModal.hideButtonReturn = true;
        this.genericModal.hideButtonSave = true;
        this.genericModal.returnButtonClicked = old;
        this.genericModal.hidePlusButton = false;

        // Reseta os dados da experienciaProfissional
        this.$store.dispatch(
          "resExperienciasProfissionais/resetExperienciaProfissional"
        );
      }
    },
  },
};
</script>

<style></style>
