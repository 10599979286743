<template>
  <section>
    <v-row>
      <v-col cols="12" sm="4">
        <CardInformacoesPessoais />
      </v-col>
      <v-col cols="12" sm="8">
        <CardObjetivosProfissionais />
      </v-col>
    </v-row>
    <v-row>
      <v-col offset-sm="4" cols="12" sm="8">
        <CardAtividades />
      </v-col>
    </v-row>
    <v-row>
      <v-col offset-sm="4" cols="12" sm="8">
        <CardExperienciaProfissional />
      </v-col>
    </v-row>
    <v-row>
      <v-col offset-sm="4" cols="12" sm="8">
        <CardCandidatoFormacao />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import CardInformacoesPessoais from "./components/CardInformacoesPessoais.vue";
import CardObjetivosProfissionais from "./components/CardObjetivosProfissionais.vue";
import CardAtividades from "./components/CardAtividades.vue";
import CardExperienciaProfissional from "./components/CardExperienciaProfissional.vue";
import CardCandidatoFormacao from "./components/CardCandidatoFormacao.vue";

export default {
  name: "InformacoesTab",
  components: {
    CardInformacoesPessoais,
    CardObjetivosProfissionais,
    CardAtividades,
    CardExperienciaProfissional,
    CardCandidatoFormacao,
  },
};
</script>

<style></style>
