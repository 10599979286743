<template>
  <section>
    <v-tabs :vertical="!isMobile">
      <v-tab
        v-for="tab in tabs"
        :key="`tab-${tab.id}`"
        :disabled="tab[genericModal.nameComponent] == null ? true : false"
      >
        <v-icon left> {{ tab.icon }} </v-icon>
        {{ tab.title }}
      </v-tab>

      <v-tab-item v-for="tab in tabs" :key="`tab-item-${tab.id}`">
        <div class="ma-2">
          <component :is="tab[genericModal.nameComponent]" />
        </div>
      </v-tab-item>
    </v-tabs>
  </section>
</template>

<script>
export default {
  name: "InformacoesGeral",
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: "Pessoais",
          icon: "mdi-account",
          componentView: () =>
            import("./components/InformacoesPessoaisView.vue"),
          componentEdit: () =>
            import("./components/InformacoesPessoaisEdit.vue"),
        },
        {
          id: 2,
          title: "Profissionais",
          icon: "mdi-account-tie",
          componentView: () =>
            import("./components/InformacoesProfissionaisView.vue"),
          componentEdit: () =>
            import("./components/InformacoesProfissionaisEdit.vue"),
        },
        {
          id: 3,
          title: "Sensíveis",
          icon: "mdi-shield-lock",
          componentView: null,
          componentEdit: () => import("./components/InformacoesSensiveis.vue"),
        },
      ],
    };
  },
  computed: {
    genericModal: {
      get() {
        return this.$store.getters["resCandidatos/getModal"];
      },
    },
    /**
     * @description Verifica se o usuário que está acessando é Mobile ou Desktop, caso for mobile será retornado TRUE, senão FALSE será retornado.
     *
     * @return boolean
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    isMobile() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true; // está utilizando celular
      } else {
        return false; // não é celular
      }
    },
  },
};
</script>

<style></style>
