import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loading,"height":"100%"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VCardTitle,[_vm._v("Objetivo profissional")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","x-small":"","color":"accent"},on:{"click":function($event){return _vm.edit()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Editar objetivo")])])],1),_c(VCardText,[(_vm.objetivoProfissional.objetivo_profissional)?_c('p',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.objetivoProfissional.objetivo_profissional)+" ")]):_c('h3',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" Não há nenhum objetivo profissional. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }