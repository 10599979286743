<template>
  <v-row class="ma-3">
    <v-col cols="12">
      <v-textarea
        label="Objetivo profissional"
        placeholder="Dígite o seu objetivo profissional"
        required
        outlined
        dense
        counter="250"
        v-model.trim="objetivoProfissional.objetivo_profissional"
        data-vv-as="objetivo profissional"
        persistent-hint
        :hint="errors.first('res_objetivo_profissional.objetivoProfissional')"
        :error="
          errors.collect('res_objetivo_profissional.objetivoProfissional')
            .length
            ? true
            : false
        "
        v-validate="'required|min:10|max:250'"
        data-vv-scope="res_objetivo_profissional"
        data-vv-validate-on="change"
        id="objetivoProfissional"
        name="objetivoProfissional"
      ></v-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ObjetivosProfissionaisForm",
  inject: ["$validator"],
  computed: {
    objetivoProfissional: {
      get() {
        return this.$store.getters[
          "resObjetivosProfissionais/getObjetivoProfissional"
        ];
      },
      set(value) {
        this.$store.dispatch("resObjetivosProfissionais/setObjetivoProfissional", value);
      },
    },
  },
};
</script>

<style></style>
