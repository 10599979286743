import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"100%"}},[_c(VCardTitle,[_vm._v("Atividades")]),_c(VCardText,[_c(VTimeline,{attrs:{"reverse":false,"align-top":"","dense":""}},[_c(VTimelineItem,{attrs:{"color":"accent","small":""}},[_c('h3',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" Candidatou-se a uma vaga de emprego ")]),_c('p',{staticClass:"text-subtitle-2 pa-0 ma-0"},[_vm._v(" Você se candidatou a vaga de "),_c('strong',[_vm._v("Desenvolvedor Full-Stack.")])]),_c('p',[_c(VIcon,[_vm._v("mdi-calendar")]),_vm._v(" 17/01/2023")],1)]),_c(VTimelineItem,{attrs:{"color":"accent","small":""}},[_c('h3',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" Progresso! Você passou de uma fase do recrutamento ")]),_c('p',{staticClass:"text-subtitle-2 pa-0 ma-0"},[_vm._v(" Parabéns! Você passou de uma fase no recrutamento da vaga "),_c('strong',[_vm._v("Desenvolvedor Node.Js Sênior.")])]),_c('p',[_c(VIcon,[_vm._v("mdi-calendar")]),_vm._v(" 17/01/2023")],1)]),_c(VTimelineItem,{attrs:{"color":"accent","small":""}},[_c('h3',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("Entrou no Legiio")]),_c('p',{staticClass:"text-subtitle-2 pa-0 ma-0"},[_vm._v(" Obrigado por fazer parte do Legiio. ")]),_c('p',[_c(VIcon,[_vm._v("mdi-calendar")]),_vm._v(" 01/01/2023")],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }