import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.orderCandidatosFormacoes)?_c(VList,[_c(VListItemGroup,_vm._l((_vm.orderCandidatosFormacoes),function(item){return _c(VListItem,{key:item.id,attrs:{"two-line":"","link":""}},[_c(VListItemContent,[_c('div',{staticClass:"d-flex flex-column flex-sm-row align-center"},[_c('div',[_c(VListItemTitle,{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.instituicao)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.curso)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.inicio))+" - "),(!item.fim)?_c('span',[_vm._v(" Até o momento ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.fim))+" ")])])],1),_c(VSpacer),_c(VBtn,{staticClass:"mx-1",attrs:{"loading":_vm.loadings.loadingEdit == ("loading-" + (item.id)) ? true : false,"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c(VIcon,{attrs:{"size":"20","color":"accent"}},[_vm._v("mdi-pencil")])],1),_c(VBtn,{staticClass:"mx-1",attrs:{"loading":_vm.loadings.loadingDelete == ("loading-" + (item.id)) ? true : false,"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c(VIcon,{attrs:{"size":"20","color":"error"}},[_vm._v("mdi-delete")])],1)],1)])],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }