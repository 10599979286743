import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loading,"height":"100%"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VCardTitle,{staticClass:"font-weight-regular text-subtitle-1 pa-4"},[_vm._v(" Informações pessoais ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","x-small":"","color":"accent"},on:{"click":function($event){return _vm.openModal('Editando Informações Pessoais')}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Editar informações")])])],1),_c(VCardText,{staticClass:"pb-0"},[(_vm.candidato && _vm.candidato.nome)?_c('p',{staticClass:"mb-2"},[_c('strong',[_vm._v("Nome completo:")]),_vm._v(" "+_vm._s(_vm.candidato.nome)+" ")]):_vm._e(),(_vm.candidato && _vm.candidato.habilitacao)?_c('p',{staticClass:"mb-2"},[_c('strong',[_vm._v("Habilitação:")]),_vm._l((_vm.candidato.habilitacao),function(habilitacao){return _c(VChip,{key:habilitacao,staticClass:"mx-1",attrs:{"small":"","color":"info"}},[_vm._v(" "+_vm._s(habilitacao)+" ")])})],2):_vm._e(),(_vm.candidato && _vm.candidato.contato)?_c('p',{staticClass:"mb-2"},[_c('strong',[_vm._v("Contato:")]),_vm._v(" "+_vm._s(_vm._f("maskForCel")(_vm.candidato.contato))+" ")]):_vm._e(),(_vm.candidato && _vm.candidato.email)?_c('p',{staticClass:"mb-2"},[_c('strong',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.candidato.email)+" ")]):_vm._e()]),_c(VCardActions,{staticClass:"pb-2"},[_c(VBtn,{attrs:{"text":"","plain":"","color":"accent"},on:{"click":function($event){return _vm.openModal('Visualizando Informações Pessoais', false)}}},[_vm._v(" Ver mais... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }