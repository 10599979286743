<template>
  <v-card height="100%">
    <v-card-title>Atividades</v-card-title>
    <v-card-text>
      <v-timeline :reverse="false" align-top dense>
        <v-timeline-item color="accent" small>
          <h3 class="text-subtitle-1 font-weight-medium">
            Candidatou-se a uma vaga de emprego
          </h3>
          <p class="text-subtitle-2 pa-0 ma-0">
            Você se candidatou a vaga de
            <strong>Desenvolvedor Full-Stack.</strong>
          </p>
          <p><v-icon>mdi-calendar</v-icon> 17/01/2023</p>
        </v-timeline-item>

        <v-timeline-item color="accent" small>
          <h3 class="text-subtitle-1 font-weight-medium">
            Progresso! Você passou de uma fase do recrutamento
          </h3>
          <p class="text-subtitle-2 pa-0 ma-0">
            Parabéns! Você passou de uma fase no recrutamento da vaga
            <strong>Desenvolvedor Node.Js Sênior.</strong>
          </p>
          <p><v-icon>mdi-calendar</v-icon> 17/01/2023</p>
        </v-timeline-item>

        <v-timeline-item color="accent" small>
          <h3 class="text-subtitle-1 font-weight-medium">Entrou no Legiio</h3>
          <p class="text-subtitle-2 pa-0 ma-0">
            Obrigado por fazer parte do Legiio.
          </p>
          <p><v-icon>mdi-calendar</v-icon> 01/01/2023</p>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardAtividades",
};
</script>

<style></style>
