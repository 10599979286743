import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"ma-3"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{directives:[{name:"validate",rawName:"v-validate",value:('required|min:10|max:250'),expression:"'required|min:10|max:250'"}],attrs:{"label":"Objetivo profissional","placeholder":"Dígite o seu objetivo profissional","required":"","outlined":"","dense":"","counter":"250","data-vv-as":"objetivo profissional","persistent-hint":"","hint":_vm.errors.first('res_objetivo_profissional.objetivoProfissional'),"error":_vm.errors.collect('res_objetivo_profissional.objetivoProfissional')
          .length
          ? true
          : false,"data-vv-scope":"res_objetivo_profissional","data-vv-validate-on":"change","id":"objetivoProfissional","name":"objetivoProfissional"},model:{value:(_vm.objetivoProfissional.objetivo_profissional),callback:function ($$v) {_vm.$set(_vm.objetivoProfissional, "objetivo_profissional", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"objetivoProfissional.objetivo_profissional"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }