<template>
  <v-row>
    <!-- Trabalho atual -->
    <v-col cols="12">
      <v-switch
        v-model="experienciaProfissional.trabalho_atual"
        :label="`Este é meu trabalho atual? ${
          experienciaProfissional.trabalho_atual == '1' ? 'Sim' : 'Não'
        }`"
        true-value="1"
        false-value="0"
        hide-details
      ></v-switch>
    </v-col>
    <!-- função -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="Função"
        ref="funcao"
        placeholder="Ex.: Estagiário Comercial."
        outlined
        dense
        v-model="experienciaProfissional.funcao"
        data-vv-as="função"
        persistent-hint
        :hint="errors.first('res-experiencia.funcao')"
        :error="errors.collect('res-experiencia.funcao').length ? true : false"
        :hide-details="
          errors.collect('res-experiencia.funcao').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-experiencia"
        id="funcao"
        name="funcao"
      ></v-text-field>
    </v-col>
    <!-- empresa -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="Empresa"
        ref="empresa"
        placeholder="Ex.: Legiio."
        outlined
        dense
        v-model="experienciaProfissional.empresa"
        data-vv-as="empresa"
        persistent-hint
        :hint="errors.first('res-experiencia.empresa')"
        :error="errors.collect('res-experiencia.empresa').length ? true : false"
        :hide-details="
          errors.collect('res-experiencia.empresa').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-experiencia"
        id="empresa"
        name="empresa"
      ></v-text-field>
    </v-col>
    <!-- Data inicio -->
    <v-col cols="12" sm="6">
      <v-menu
        ref="menuInicio"
        v-model="menuInicio"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormattedInicio"
            label="Data de início"
            persistent-hint
            prepend-icon="mdi-calendar"
            outlined
            dense
            v-bind="attrs"
            @blur="
              experienciaProfissional.inicio = parseDate(dateFormattedInicio)
            "
            v-on="on"
            data-vv-as="data de início"
            :hint="errors.first('res-experiencia.inicio')"
            :error="
              errors.collect('res-experiencia.inicio').length ? true : false
            "
            :hide-details="
              errors.collect('res-experiencia.inicio').length ? false : true
            "
            v-validate="'required'"
            data-vv-validate-on="change|blur|input"
            data-vv-scope="res-experiencia"
            id="inicio"
            name="inicio"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="experienciaProfissional.inicio"
          no-title
          @input="menuInicio = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <!-- Data fim -->
    <v-col cols="12" sm="6">
      <v-menu
        :disabled="experienciaProfissional.trabalho_atual == '1' ? true : false"
        ref="menuFim"
        v-model="menuFim"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="
              experienciaProfissional.trabalho_atual == '1' ? true : false
            "
            v-model="dateFormattedFim"
            label="Data de Fim"
            persistent-hint
            prepend-icon="mdi-calendar"
            outlined
            dense
            v-bind="attrs"
            @blur="experienciaProfissional.fim = parseDate(dateFormattedFim)"
            v-on="on"
            data-vv-as="data de fim"
            :hint="errors.first('res-experiencia.fim')"
            :error="errors.collect('res-experiencia.fim').length ? true : false"
            :hide-details="
              errors.collect('res-experiencia.fim').length ? false : true
            "
            v-validate="
              experienciaProfissional.trabalho_atual == '1' ? '' : 'required'
            "
            data-vv-validate-on="change|blur|input"
            data-vv-scope="res-experiencia"
            id="fim"
            name="fim"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="experienciaProfissional.fim"
          no-title
          @input="menuFim = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <!-- Area de atuacao -->
    <v-col cols="12" sm="6">
      <v-select
        :loading="loadings.loadingAreasAtuacoes"
        :items="resAreasAtuacoes"
        v-model="experienciaProfissional.id_area"
        item-text="descricao"
        item-value="id"
        label="Área de atuação"
        placeholder="Selecione a área de atuação"
        outlined
        dense
        data-vv-as="área de atuação"
        persistent-hint
        :hint="errors.first('res-experiencia.area')"
        :error="errors.collect('res-experiencia.area').length ? true : false"
        :hide-details="
          errors.collect('res-experiencia.area').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-experiencia"
        id="area"
        name="area"
      ></v-select>
    </v-col>
    <!-- Cargos -->
    <v-col cols="12" sm="6">
      <v-select
        :loading="loadings.loadingResCargos"
        :items="resCargos"
        v-model="experienciaProfissional.id_cargo"
        item-text="descricao"
        item-value="id"
        label="Cargo"
        placeholder="Selecione o cargo"
        outlined
        dense
        data-vv-as="cargo"
        persistent-hint
        :hint="errors.first('res-experiencia.cargo')"
        :error="errors.collect('res-experiencia.cargo').length ? true : false"
        :hide-details="
          errors.collect('res-experiencia.cargo').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-experiencia"
        id="cargo"
        name="cargo"
      ></v-select>
    </v-col>
    <!-- salario -->
    <v-col cols="12">
      <v-text-field
        label="Salário"
        ref="salario"
        placeholder="Ex.: 1.200,00"
        outlined
        hide-details
        dense
        prefix="R$"
        v-model="experienciaProfissional.salario"
        v-mask="[
          '###,#',
          '###,##',
          '#.###,##',
          '##.###,##',
          '###.###,##',
          '#.###.###,##',
        ]"
      ></v-text-field>
    </v-col>
    <!-- descrição -->
    <v-col cols="12" sm="12">
      <v-textarea
        label="Descrição"
        ref="descricao"
        outlined
        dense
        v-model="experienciaProfissional.descricao"
        data-vv-as="descrição"
        persistent-hint
        :hint="errors.first('res-experiencia.descricao')"
        :error="
          errors.collect('res-experiencia.descricao').length ? true : false
        "
        :hide-details="
          errors.collect('res-experiencia.descricao').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-experiencia"
        id="descricao"
        name="descricao"
      ></v-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ExperienciasProfissionaisForm",
  inject: ["$validator"],
  data: () => ({
    dateFormattedInicio: null,
    dateFormattedFim: null,
    menuInicio: false,
    menuFim: false,

    loadings: {
      loadingAreasAtuacoes: true,
      loadingResCargos: true,
    },
  }),
  async created() {
    const data = {
      columns: ["id", "descricao"],
    };

    await this.$store.dispatch("resAreasAtuacoes/findByColumns", data);

    this.loadings.loadingAreasAtuacoes = false;

    await this.$store.dispatch("resCargos/findByColumns", data);

    this.loadings.loadingResCargos = false;
  },
  computed: {
    experienciaProfissional: {
      get() {
        const experienciaProfissional =
          this.$store.getters[
            "resExperienciasProfissionais/getExperienciaProfissional"
          ];

        this.dateFormattedInicio = this.formatDate(
          experienciaProfissional.inicio
        );
        this.dateFormattedFim = this.formatDate(experienciaProfissional.fim);

        return experienciaProfissional;
      },
      set(value) {
        this.$store.dispatch(
          "resExperienciasProfissionais/setExperienciaProfissional",
          value
        );
      },
    },
    resAreasAtuacoes: {
      get() {
        return this.$store.getters["resAreasAtuacoes/getAreasAtuacoes"];
      },
    },
    resCargos: {
      get() {
        return this.$store.getters["resCargos/getCargos"];
      },
    },
  },
  watch: {
    "experienciaProfissional.inicio"(val) {
      this.dateFormattedInicio = this.formatDate(
        this.experienciaProfissional.inicio
      );
    },
    "experienciaProfissional.fim"(val) {
      this.dateFormattedFim = this.formatDate(this.experienciaProfissional.fim);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style></style>
